// Utils
import Utils from "../common/Utils";

class Model {

  /**
   * Constructor
   */
  constructor() {
    this.state = null;
    this.score = null;
    this.counter = null;
    this.selections = null;

    this.reset();
  }

  reset() {
    this.counter = 0;

    let questions = document.querySelectorAll("[data-answer]");
    this.state = {};
    this.selections = new Array(questions.length).fill(null);

    Utils.dispatch("ESPN:RESET");
  }

  setState(score) {
    this.score = score;
    // this.state[index] = state;
    // this.selections[index] = answer;
    Utils.dispatch("ESPN:SCORE:UPDATE");

    this.counter = index + 1;
  }

  // Getter/Setter: state
  set state(state) { this._state = state; }
  get state() { return this._state; }

  // Getter/Setter: counter
  set counter(counter) { this._counter = counter; }
  get counter() { return this._counter; }

  set score(score) { this._score = score; }
  get score() {
    return this.score;
  }
}

export let model = new Model();