// Utils
import Utils from "../common/Utils";

class Nav {

  constructor() {
    this.nav = document.querySelector('.nav');
    this.hamburger = document.querySelector('.hamburger');
    this.intro = document.querySelector('.intro');
    this.navItems = document.querySelectorAll('.nav li');

    this.events();
    this.setHeight();
  }

  events() {
    const event = Utils.isTouchDevice() ? 'touchstart' : 'click';
    this.hamburger.addEventListener(event,
      () => this.toggleState()
    );

    window.addEventListener('orientationchange',
      () => {
        setTimeout(() => {
          this.setHeight();
        }, 200);
      }
    );
  }

  toggleState() {
    this.nav.classList.toggle('active');
  }

  setHeight() {
    if(Utils.isTouchDevice()) {
      const winHeight = window.innerHeight;
      let height = winHeight - 60;
      if(height < 500) height = 500;

      this.intro.style.height = `${height}px`;

      const itemHeight = (winHeight - 60) / 4;
      this.navItems.forEach(item => {
        item.style.height = `${itemHeight}px`;
      })
    }
  }

}

export default Nav;