// Model
import { model } from "./Model";

class Common {

  constructor() {

    // Class model
    this.model = {

      // template
      frame: document.querySelector(".espn-template"),

      // width
      width: null,

      // height
      height: null,

      // facebook icon
      facebook: document.querySelector("#facebook"),

      // twitter icon
      twitter: document.querySelector("#twitter")
    };

    // kickoff browser detection
    this.browserDetection();

    // kickoff events
    this.setupEvents();

    // kickoff sharing
    this.createShare();
  }

  /**
   * Browser detection
   * @return null
   */
  browserDetection() {

    // Get the device pixel ratio
    let ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    let screen = {
      width : window.screen.width * ratio,
      height : window.screen.height * ratio
    };

    // iOS
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if(iOS) {
      model.iOS = true;
      this.model.frame.classList.add("iOS");
    }

    const browser = this.get_browser().name.toLowerCase();
    const version = this.get_browser().version;
    this.model.frame.classList.add(browser, "ver" + version);

    model.browser = browser;

    if(browser === "ie") {
      model.ie = true;
    }

    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1;
    if(isAndroid) {
      this.model.frame.classList.add("android");
      model.android = true;
    }
  }

  /**
   * Get browser
   */
  get_browser() {
    var ua = navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
        }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}

    let version = M[1].indexOf(" ") > -1 ? M[1].slice(0, M[1].indexOf(" ")) : M[1];
    version = version.replace("(", "").replace(")", "").replace(";", "").replace("/", "").replace(",", "");

    return {
      name: M[0],
      version: version
    };
   }

  /**
   * Setup events
   * @return null
   */
  setupEvents() {

    // Ticker
    TweenMax.ticker.addEventListener("tick", event => this.onTick());
  }

  /**
   * Create sharing
   * @return null
   */
  createShare() {

    // Facebook
    if(this.model.facebook) {
      this.model.facebook.addEventListener("click", (event) => {
        event.preventDefault();

        let href = this.model.facebook.href;
        this.share(href);
      });
    }

    // Twitter
    if(this.model.twitter) {
      this.model.twitter.addEventListener("click", (event) => {
        event.preventDefault();

        let href = this.model.twitter.href;
        this.share(href);
      });
    }
  }

  /**
   * Share
   * @param {String} href
   */
  share(href) {
    let settings = "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600";
    window.open(href, "_blank", settings);
  }

  /**
   * Ticke handler
   * @param  {Boolean} force
   */
  onTick(force) {
    let newWidth = window.innerWidth;
    let newHeight = window.innerHeight;

    if(force || this.model.width !== newWidth || this.model.height !== newHeight) {
      this.model.width = newWidth;
      this.model.height = newHeight;

      if(this.model.frame) {
        TweenMax.set(this.model.frame, {
          autoAlpha: 1,
          // width: this.model.width,
          // height: this.model.height,
          // overflow: "hidden"
        });
      }
    }
  }
}

export default Common;