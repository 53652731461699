class Utils {

  // Dispatch custom event
  static dispatch(event, payload) {
    var customEvent = new CustomEvent(event, { detail: payload });
    document.dispatchEvent(customEvent);
  }

  // Shuffle
  static shuffle(array) {
    let counter = array.length;
    while (counter > 0) {
      let index = Math.floor(Math.random() * counter);

      counter--;

      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }

    return array;
  }

  /**
   * Is touch device
   * @return {Boolean}
   */
  static isTouchDevice() {
    return "ontouchstart" in document.documentElement;
  }

  static getIndex(collection, target) {
    return [].indexOf.call(collection, target)
  }

  static getArrayFromNodeList(nodelist) {
    // return [].slice.call(nodelist);
    return Array.from(nodelist);
  }

  static abbreviate(number, maxPlaces, forcePlaces, forceLetter) {
    number = Number(number)
    forceLetter = forceLetter || false
    if(forceLetter !== false) {
      return this.annotate(number, maxPlaces, forcePlaces, forceLetter)
    }
    var abbr
    if(number >= 1e12) {
      abbr = 't'
    }
    else if(number >= 1e9) {
      abbr = 'b'
    }
    else if(number >= 1e6) {
      abbr = 'm'
    }
    else if(number >= 1e3) {
      abbr = 'k'
    }
    else {
      abbr = ''
    }
    return this.annotate(number, maxPlaces, forcePlaces, abbr)
  }

  static annotate(number, maxPlaces, forcePlaces, abbr) {
    // set places to false to not round
    var rounded = 0
    switch(abbr) {
      case 't':
        rounded = number / 1e12
        break
      case 'b':
        rounded = number / 1e9
        break
      case 'm':
        rounded = number / 1e6
        break
      case 'k':
        rounded = number / 1e3
        break
      case '':
        rounded = number
        break
    }
    if(maxPlaces !== false) {
      var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$')
      if(test.test(('' + rounded))) {
        rounded = rounded.toFixed(maxPlaces)
      }
    }
    if(forcePlaces !== false) {
      rounded = Number(rounded).toFixed(forcePlaces)
    }
    return rounded + abbr
  }

  /**
   * add commas
   */
  // static addCommas(x) {
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  /**
   * Get browser
   */
  static get_browser() {
    var ua = navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name:'IE',version:(tem[1]||'')};
        }
    if(M[1]==='Chrome'){
        tem=ua.match(/\bOPR|Edge\/(\d+)/)
        if(tem!=null)   {return {name:'Opera', version:tem[1]};}
        }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}

    let version = M[1].indexOf(" ") > -1 ? M[1].slice(0, M[1].indexOf(" ")) : M[1];
    version = version.replace("(", "").replace(")", "").replace(";", "").replace("/", "").replace(",", "");

    return {
      name: M[0],
      version: version
    };
  }
  /**
   * debounce
   * @param  {Function} func
   * @param  {Number} wait
   * @param  {Boolean} immediate
   * @return {Function}
   */
  static debounce(func, wait, immediate) {
    let timeout;
    return () => {
      let context = this, args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    }
  }

  /**
   * Create SVG
   * @param  {Object} opts configuration
   * @return {SVG}
   */
  static createSVG(opts) {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", opts.type);
    if(opts.type === "text") {
      var anchor = "middle";
      if(opts.anchor) {
        anchor = opts.anchor;
      }
      var alignment = "auto";
      if(opts.alignment) {
        alignment = opts.alignment;
      }
      svg.setAttribute("x", opts.x);
      svg.setAttribute("y", opts.y);
      svg.setAttribute("text-anchor", opts.anchor);
      svg.setAttribute("alignment-baseline", alignment);
    } else if(opts.type === "line") {
      svg.setAttribute("x1", opts.x1);
      svg.setAttribute("y1", opts.y1);
      svg.setAttribute("x2", opts.x2);
      svg.setAttribute("y2", opts.y2);

      if(opts.style === "dotted") {
        svg.setAttribute("stroke-dasharray", "1, 2");
        svg.setAttribute("stroke-width", "1");
      }
    } else if(opts.type === "path") {
      svg.setAttribute("d", opts.d);
    } else if(opts.type === "circle") {
      svg.setAttribute("cx", opts.cx);
      svg.setAttribute("cy", opts.cy);
      svg.setAttribute("r", opts.r);
    }

    if(opts.className) {
      svg.setAttribute("class", opts.className);
    }

    if(opts.caption) {
      svg.textContent = opts.caption;
    }

    return svg;
  }

  static addCommas(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  static isIpad() {
    return navigator.userAgent.match(/iPad/i) !== null;
  }

  /**
   * Track a custom link event in Adobe Analytics
   * @param {String} customEvent The type of event that is happening (e.g., profile:previous:click)
   */
  static track(customEvent) {
    let trackingId = document.querySelector("[data-tracking-id]");
    trackingId = trackingId && trackingId.dataset.trackingId ?
                                trackingId.dataset.trackingId :
                                "espncom";

    // console.log('track', customEvent);
    // console.log(trackingId + ':' + customEvent);

    const canESPNAndTrack = window.espn && window.espn.track;
    if(canESPNAndTrack) {

      window.espn.track.trackLink({
        linkPos: trackingId + ':' + customEvent,
        linkId: null
      });
    }
  }
}


export default Utils;