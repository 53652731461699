// Common
import Polyfills from "./common/Polyfills";

// Model
import { model } from "./common/Model";

// Common
import Common from "./common/Common";

// Utils
import Utils from "./common/Utils";

import Slideshow from "./components/Slideshow";
import LazyLoad from "./components/LazyLoad";
import Nav from "./components/Nav";

window.ESPN = window.ESPN || {};

class App {

	constructor () {
    this.kickoff();
	}

  /**
   * kickoff
   */
  kickoff() {
    new Slideshow();
    new LazyLoad();
    new Nav();
  }
}

window.ESPN.App = new App();