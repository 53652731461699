// Utils
import Utils from "../common/Utils";

class Slideshow {

  constructor() {
    this.slideshow = document.querySelector('.slideshow');
    this.slides = document.querySelectorAll('.slide');
    this.counter = 0;
    this.slideCounter = 0;
    this.zCounter = 0;
    this.baseurl = document.body.dataset.baseurl;

    this.events();
    this.initLoad();
  }

  initLoad() {
    const img = new Image();
    const bg = this.slides[0].querySelector('[data-slide]');
    const src = this.baseurl + bg.dataset.slide;

    img.onload = () => {
      bg.style.backgroundImage = `url(${src})`;
      this.nextSlide();

      setTimeout(() => {
        Utils.dispatch('PEAK:LOAD_MENU');
      }, 500);
    }

    img.src = `${src}`;
  }

  events() {
    TweenMax.ticker.addEventListener(
      'tick', () => this.onTick()
    );
  }

  onTick() {
    if(this.slides.length === 1) return;

    this.counter++;
    if(this.counter % 225 === 0) {
      this.nextSlide();
    }
  }

  nextSlide() {
    this.zCounter++;
    const nextSlide = this.slides[this.slideCounter];

    this.loadSlide(nextSlide);

    if(this.slideCounter < this.slides.length - 1) {
      this.slideCounter++;
    } else {
      this.slideCounter = 0;
    }
  }

  loadSlide(nextSlide) {
    const img = new Image();
    const bg = nextSlide.querySelector('[data-slide]');
    const src = this.baseurl + bg.dataset.slide;

    img.onload = () => {
      bg.style.backgroundImage = `url(${src})`;
      this.animateSlide(nextSlide);
    }

    img.src = `${src}`;
  }

  animateSlide(nextSlide) {
    if(this.currentSlide) {
      TweenMax.to(this.currentSlide, 0, {
        autoAlpha: 0,
        scale: 1.1,
        ease: Quad.easeInOut,
        delay: 1.01,
        onComplete: () => {
          this.currentSlide = nextSlide;
        }
      });
    } else {
      this.currentSlide = nextSlide;
    }

    TweenMax.to(nextSlide, 1, {
      autoAlpha: 1,
      scale: 1,
      ease: Quad.easeOut,
      zIndex: this.zCounter
    });
  }

}

export default Slideshow;