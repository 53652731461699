// Model
import { model } from "../common/Model";

import Utils from "../common/Utils";

class LazyLoad {

  constructor() {
    this.baseurl = document.body.dataset.baseurl;

    this.images = Utils.getArrayFromNodeList(
      document.querySelectorAll('[data-src], [data-bg]')
    );

    this.navImages = Utils.getArrayFromNodeList(
      document.querySelectorAll('[data-nav-bg]')
    );

    this.events();
  }

  events() {
    // intersection observer
    this.observer = new IntersectionObserver((entries, observer) =>
      this.check(entries, observer)
    ,
    {
      rootMargin: "200px",
      threshold: 0
    });

    this.images.forEach(image => {
      this.observer.observe(image);
    });

    document.addEventListener('PEAK:LOAD_MENU', () => {
      this.navImages.forEach(image => {
        this.observer.observe(image);
      });
    });
  }

  /**
   * check lazy load
   * @param  {DOMCollection}   entries
   * @param  {IntersectionObserver}   observer
   */
  check(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        this.loadImage(entry.target);
        this.observer.unobserve(entry.target);
      }
    });
  }

  /**
   * Preload image
   * @param  {DOMElement} image
   */
  loadImage(image) {
    const placeholder = new Image();
    const src = image.dataset.src || image.dataset.bg || image.dataset.navBg;

    const base = src.indexOf('http') > -1 ? '' : this.baseurl;

    placeholder.onload = (event) => this.imageLoaded(image);
    placeholder.src = base + src;
  }

  /**
   * image loaded
   */
  imageLoaded(image) {

    if(image.dataset.src) {
      const base = image.dataset.src.indexOf('http') > -1 ? '' : this.baseurl;
      image.src = base + image.dataset.src;
      image.classList.add("visible");
    } else if(image.dataset.bg) {
      const base = image.dataset.bg.indexOf('http') > -1 ? '' : this.baseurl;
      const url = base + image.dataset.bg;
      image.style.backgroundImage = "url('" + url + "')";
      image.classList.add("visible");
    } else if(image.dataset.navBg) {
      const base = image.dataset.navBg.indexOf('http') > -1 ? '' : this.baseurl;
      const url = base + image.dataset.navBg;
      image.style.backgroundImage = "url('" + url + "')";
    }
  }

}

export default LazyLoad;